<template>
  <div>
    <v-row class="d-flex justify-end">
      <v-col>
        <h1>
          {{
            $t("emulation.listTitle", {
              target: getServiceName({ scope: target }),
            })
          }}
        </h1>
      </v-col>
      <v-col cols="4" class="text-end">
        <v-btn
          small
          class="mr-2"
          color="primary"
          :title="$t('sync')"
          @click="syncDialog = true"
        >
          {{ $t("sync") }}
        </v-btn>
      </v-col>
    </v-row>
    <emulation-settings-search v-if="dataLoaded"></emulation-settings-search>
    <v-divider class="mt-5"></v-divider>
    <v-tabs
      class="mt-2"
      background-color="grey lighten-4"
      v-model="selectedTarget"
      @change="changeTarget"
    >
      <v-tab
        v-for="item in availableScopes"
        :key="item"
        :title="
          $t('recommender.settingsFor', {
            target: getServiceName({ scope: item }),
          })
        "
      >
        {{ getServiceName({ scope: item }) }}
      </v-tab>
    </v-tabs>
    <div class="mt-3">
      <!-- Data loader -->
      <v-overlay
        align-center
        justify-center
        opacity="0.15"
        :absolute="true"
        :value="dataLoading"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div>
        <!--    Data table     -->
        <v-data-iterator
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
          v-if="!dataLoading"
          :items="items"
          :no-data-text="$t('no-data')"
        >
          <template v-slot:header>
            <!--    Table header begin -->
            <div
              v-if="totalCount > 0"
              class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0"
            >
              <v-row class="grey--text body-2">
                <v-col cols="2" lg="3" class="align-self-end">
                  {{ $t("recommender.segmentSettings") }}
                </v-col>
              </v-row>
            </div>
            <!--    Table header end -->
          </template>
          <template v-slot:default="props">
            <emulation-settings-item
              v-for="(item, i) in props.items"
              :key="i"
              :settings="item"
              @editInfo="editItem(item)"
              @deleteItem="deleteConfirmation(item)"
            ></emulation-settings-item>
          </template>
        </v-data-iterator>
        <!--    TTable pagination -->
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>

    <!--  Setting form dialog    -->
    <v-dialog fullscreen v-model="editDialog" max-width="900px">
      <emulation-settings-form
        v-if="editDialog"
        :item="selectedItem"
        :scope="selectedScope"
        :segment="selectedSegment"
        @close="closeEditDialog"
        @submitted="
          closeEditDialog();
          loadItems();
        "
      ></emulation-settings-form>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("deleteConfirmation") }}
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{ $t("recommender.deleteConfirmation") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem(selectedItem)">
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="syncDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          {{ $t("syncConfirmation") }}
          <v-btn icon color="white" @click="syncDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          {{
            $t("emulation.syncConfirmationText", {
              target: getServiceName({ scope: target }),
            })
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="syncDialog = false">{{
            $t("close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="syncSettings()">
            {{ $t("sync") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import BaseListPage from "@/views/base/BaseListPage";

import EmulationSettingsForm from "./EmulationSettingsForm";
import EmulationSettingsItem from "./list/EmulationSettingsItem";
import EmulationSettingsSearch from "./list/EmulationSettingsSearch";

import { mapGetters, mapState } from "vuex";
import { FETCH_SERVICES } from "@/store/actions/service";

import { SCOPE_FACEBOOK } from "../../store/modules/service/getters";

import {
  defaultFilters,
  multipleFilters,
} from "@/store/modules/emulation/state";

import {
  SET_FILTER,
  FETCH_EMULATION_SETTINGS,
  DELETE_EMULATION_SETTINGS,
} from "@/store/actions/emulation-settings";
import { SYNC_EMULATION_SETTINGS } from "@/store/actions/emulation-settings";

export default {
  name: "EmulationSettingsList",
  extends: BaseListPage,
  components: {
    EmulationSettingsForm,
    EmulationSettingsItem,
    EmulationSettingsSearch,
  },
  props: {
    target: String,
  },
  data: function () {
    return {
      deleteDialog: false,
      dataLoaded: false,
      syncDialog: false,
      selectedTarget: 0,
      defaultFilters: _.merge({}, defaultFilters),
      multipleFilters,
    };
  },
  computed: {
    ...mapState("emulation", ["pagination", "filters", "sort", "emptyFilters"]),
    ...mapGetters("service", ["scopes", "getServiceName"]),
    ...mapGetters("emulation", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "formattedFilters",
      "availableSegments",
      "availableScopes",
    ]),
    selectedSegment: function () {
      let component = this;
      let selectedSegment = {};
      this.availableSegments.forEach(function (segment) {
        selectedSegment[segment] = component.filters[segment];
      });
      return selectedSegment;
    },
    selectedScope: function () {
      return _.get(this.availableScopes, this.selectedTarget, SCOPE_FACEBOOK);
    },
  },
  watch: {
    target: function () {
      this.loadItems();
      this.selectedTarget = this.availableScopes.indexOf(this.target);
    },
  },
  methods: {
    applyFilters: function (data) {
      this.$store.dispatch("emulation/" + SET_FILTER, data.data);
    },
    loadServices: function () {
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("emulation/" + FETCH_EMULATION_SETTINGS, {
          type: this.selectedScope,
        })
        .then(
          () => {
            this.dataLoading = false;
            this.dataLoaded = true;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    syncSettings: function () {
      this.$store
        .dispatch("emulation/" + SYNC_EMULATION_SETTINGS, {
          type: this.selectedScope,
        })
        .then(
          () => {
            this.syncDialog = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    deleteConfirmation: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("emulation/" + DELETE_EMULATION_SETTINGS, item)
        .then(
          (response) => {
            if (response.status === true) {
              this.$notify({
                group: "messages",
                title: this.$t("recommender.deleteSettings"),
                text: this.$t("recommender.deleteSettingsSuccess"),
              });
              this.dataLoading = false;
              this.selectedItem = null;
              this.deleteDialog = false;
              this.loadItems();
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    changeTarget: function () {
      this.$router
        .push({
          name: "EmulationSettingsList",
          params: { target: this.selectedScope },
          query: this.$route.query,
        })
        .catch();
    },
  },
  mounted() {
    this.applyRouteParams();
    this.loadServices();
    this.selectedTarget = this.availableScopes.indexOf(this.target);
  },
  sockets: {
    contentRecommender: function (message) {
      this.$notify({
        group: "error",
        type: "error",
        title: this.$t("syncError"),
        text: message,
      });
    },
  },
};
</script>

<style>
.theme--light.v-divider {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
  border-top-width: 1px;
}
.v-expansion-panel-content__wrap {
  padding: 0 12px 16px;
}
.v-icon.v-icon::after {
  background-color: unset;
}
</style>
