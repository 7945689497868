<template>
  <div>
    <div class="d-flex justify-space-between mb-2 px-3">
      <span class="mb-3 text-subtitle-1">{{
        $t(`emulation.params.${label}`)
      }}</span>
      <div>
        <v-row>
          <v-col>
            <v-text-field
              :readonly="readonly"
              v-model="itemValue[0]"
              :label="$t('min')"
              class="mt-0 pt-0"
              outlined
              dense
              style="width: 140px"
              :rules="[isNumeric]"
              @change="handleInput()"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :readonly="readonly"
              v-model="itemValue[1]"
              :label="$t('max')"
              class="mt-0 pt-0"
              outlined
              dense
              style="width: 140px"
              :rules="[isNumeric]"
              @change="handleInput()"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-alert
      v-if="value.appliedValue && value.appliedValue !== value.value"
      dense
      outlined
      type="info"
    >
      {{ $t("emulation.settingsDiff") }}<br />
      {{ $t("emulation.appliedValue", { appliedValue: value.appliedValue }) }}
    </v-alert>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "EmulationSettingsFormItemRange",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      itemValue: this.value.value
        ? this.value.value.split("-").map(function (item) {
            return isNaN(item) ? 0 : Number(item);
          })
        : [0, 0],
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value.value
          ? this.value.value.split("-").map(function (item) {
              return isNaN(item) ? 0 : Number(item);
            })
          : [0, 0];
      },
      deep: true,
    },
  },
  methods: {
    isNumeric: function (v) {
      return (
        (Number(v) !== false &&
          Number.isInteger(Number(v)) &&
          Number(v) >= 0) ||
        this.$t("defaultErrors.integer")
      );
    },
    handleInput: function () {
      this.itemValue = [
        isNaN(this.itemValue[0]) ? 0 : Number(this.itemValue[0]),
        isNaN(this.itemValue[1]) ? 0 : Number(this.itemValue[1]),
      ];
      this.$emit(
        "input",
        _.merge(this.value, {
          value: this.itemValue.join("-"),
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
