<template>
  <div>
    <div class="d-flex justify-space-between mb-2 px-3">
      <span class="mb-3 text-subtitle-1" style="line-height: 65px">{{
        $t(`emulation.params.${label}`)
      }}</span>
      <div>
        <v-autocomplete
          v-model="itemValue"
          multiple
          outlined
          dense
          density="compact"
          item-value="value"
          item-text="title"
          style="max-width: 500px"
          :label="$t(`emulation.params.${label}`)"
          :single-line="true"
          :readonly="readonly"
          :items="items"
          @input="handleInput"
        >
        </v-autocomplete>
      </div>
    </div>
    <v-alert
      v-if="value.appliedValue && value.appliedValue !== value.value"
      dense
      outlined
      type="info"
    >
      {{ $t("emulation.settingsDiff") }}<br />
      {{ $t("emulation.appliedValue", { appliedValue: value.appliedValue }) }}
    </v-alert>
  </div>
</template>

<script>
import _ from "lodash";

import { VALUE_MAPPING } from "@/store/modules/emulation/getters";

export default {
  name: "EmulationSettingsFormItemMultiselect",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      itemValue: [],
    };
  },
  computed: {
    items: function () {
      return _.get(VALUE_MAPPING, this.label, []);
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = [];
        const selectedValuesAsArray = this.value.value.split(",");
        selectedValuesAsArray.forEach((item) => {
          this.itemValue.push(item);
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      let selectedItem = this.value;
      selectedItem.value = this.itemValue.toString();
      this.$emit("input", selectedItem);
    },
  },
  mounted() {
    const multiselectAvailableValuesAsArray = this.value.value.split(",");
    multiselectAvailableValuesAsArray.forEach((item) => {
      this.itemValue.push(item);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
