<template>
  <div>
    <div class="d-flex justify-space-between mb-2 px-3">
      <span class="mb-3 text-subtitle-1" style="line-height: 65px">{{
        $t(`emulation.params.${label}`)
      }}</span>
      <div>
        <v-text-field
          :readonly="readonly"
          v-model="itemValue.value"
          :label="$t(`emulation.params.${label}`)"
          class="mt-0 pt-0"
          outlined
          dense
          style="width: 305px"
          :rules="[isNumeric]"
          @change="handleInput()"
        ></v-text-field>
      </div>
    </div>
    <v-alert
      v-if="value.appliedValue && value.appliedValue !== value.value"
      dense
      outlined
      type="info"
    >
      {{ $t("emulation.settingsDiff") }}<br />
      {{ $t("emulation.appliedValue", { appliedValue: value.appliedValue }) }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "EmulationSettingsFormItemNumber",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      itemValue: this.value,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    isNumeric: function (v) {
      return (
        (Number(v) !== false &&
          Number.isInteger(Number(v)) &&
          Number(v) >= 0) ||
        this.$t("defaultErrors.integer")
      );
    },
    handleInput: function () {
      this.itemValue.value = Number(this.itemValue.value)
        ? Number(this.itemValue.value)
        : 0;
      this.$emit("input", this.itemValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
