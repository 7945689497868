<template>
  <div>
    <div class="d-flex justify-space-between mb-2 px-3">
      <span class="mb-3 text-subtitle-1" style="line-height: 65px">{{
        $t(`emulation.params.${label}`)
      }}</span>
      <div>
        <div v-for="(item, index) in this.itemValue" :key="index">
          <span class="text-caption pl-12">{{
            $t("emulation.friendCount")
          }}</span>
          <v-row class="pt-3">
            <v-col>
              <v-icon v-if="!readonly" @click="removeValue(index)"
                >mdi-close</v-icon
              >
            </v-col>
            <v-col>
              <v-text-field
                :readonly="readonly"
                v-model="itemValue[index].friends.min"
                :rules="[isNumeric]"
                :label="$t('min')"
                class="mt-0 pt-0"
                outlined
                dense
                style="width: 140px"
                @change="handleInput()"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :readonly="readonly"
                v-model="itemValue[index].friends.max"
                :rules="[isNumeric]"
                :label="$t('max')"
                class="mt-0 pt-0"
                outlined
                dense
                style="width: 140px"
                @change="handleInput()"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="pl-12">
            <span class="text-caption">{{ $t("emulation.postCount") }}</span>
            <v-row class="pt-3">
              <v-col>
                <v-text-field
                  :readonly="readonly"
                  v-model="itemValue[index].posts.min"
                  :rules="[isNumeric]"
                  :label="$t('min')"
                  class="mt-0 pt-0"
                  outlined
                  dense
                  style="width: 140px"
                  @change="handleInput()"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :readonly="readonly"
                  v-model="itemValue[index].posts.max"
                  :rules="[isNumeric]"
                  :label="$t('max')"
                  class="mt-0 pt-0"
                  outlined
                  dense
                  style="width: 140px"
                  @change="handleInput()"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-chip
          v-if="!readonly"
          small
          outlined
          color="primary"
          @click="addValue"
          class="ml-12"
        >
          + {{ $t("add") }}
        </v-chip>
      </div>
    </div>
    <v-alert
      v-if="value.appliedValue && value.appliedValue !== value.value"
      dense
      outlined
      type="info"
    >
      {{ $t("emulation.settingsDiff") }}<br />
      {{ $t("emulation.appliedValue", { appliedValue: value.appliedValue }) }}
    </v-alert>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "EmulationSettingsFormItemScrollDownLimit",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      itemValue: this.preparedValue(),
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.preparedValue();
      },
      deep: true,
    },
  },
  methods: {
    isNumeric: function (v) {
      return (
        (Number(v) !== false &&
          Number.isInteger(Number(v)) &&
          Number(v) >= 0) ||
        this.$t("defaultErrors.integer")
      );
    },
    handleInput: function () {
      let value = [];

      this.itemValue.forEach(function (item) {
        value.push(
          `${item.friends.min}-${item.friends.max}|${item.posts.min}-${item.posts.max}`
        );
      });

      this.$emit(
        "input",
        _.merge(this.value, {
          value: value.join(","),
        })
      );
    },
    removeValue: function (index) {
      this.itemValue.splice(index, 1);
      this.handleInput();
    },
    addValue: function () {
      this.itemValue.push({
        friends: {
          min: 0,
          max: 0,
        },
        posts: {
          min: 0,
          max: 0,
        },
      });
    },
    preparedValue: function () {
      let items = [];

      if (this.value.value) {
        this.value.value.split(",").forEach(function (item) {
          let parsedItem = item.split("|");
          if (parsedItem.length === 2) {
            let parsedFriends = parsedItem[0].split("-");
            let parsedPosts = parsedItem[1].split("-");
            if (parsedFriends.length === 2 && parsedPosts.length === 2) {
              items.push({
                friends: {
                  min: isNaN(parsedFriends[0]) ? 0 : Number(parsedFriends[0]),
                  max: isNaN(parsedFriends[1]) ? 0 : Number(parsedFriends[1]),
                },
                posts: {
                  min: isNaN(parsedPosts[0]) ? 0 : Number(parsedPosts[0]),
                  max: isNaN(parsedPosts[1]) ? 0 : Number(parsedPosts[1]),
                },
              });
            }
          }
        });
      }

      if (items.length === 0) {
        items.push({
          friends: {
            min: 0,
            max: 0,
          },
          posts: {
            min: 0,
            max: 0,
          },
        });
      }
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
