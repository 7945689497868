<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pr-2 pl-4"
      ><v-row>
        <v-col cols="12" sm="12" md="3" lg="2">
          <div v-if="hasFormattedSegment">
            <div v-for="(segment, label) in formattedSegment" :key="label">
              <span class="grey--text body-2 mb-0">
                {{ attributeLabels[label] }}:
              </span>
              {{ prepareAttributeValue(label, segment) }}
            </div>
          </div>
          <div v-else>{{ $t("recommender.allAccounts") }}</div>
          <v-icon
            v-if="settings.hasDifferences"
            color="orange"
            :title="$t('emulation.settingsDiff')"
            >mdi-alert</v-icon
          >
        </v-col>
        <v-col cols="12" sm="12" md="7" lg="9">
          <v-chip
            outlined
            x-small
            v-for="(item, index) in enabledModules"
            :key="index"
          >
            {{ $t(`emulation.params.${item}`) }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="1" class="self">
          <v-btn
            x-small
            icon
            color="primary"
            class="mr-2"
            @click="$emit('editInfo')"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row></v-card-text
    >
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "EmulationSettingsItem",
  props: {
    settings: Object,
  },
  data: function () {
    return {
      attributeLabels: {
        type: this.$t("account.attribute.type"),
        stage: this.$t("account.attribute.stage"),
      },
      //List of selected related items
      selectedTags: [],
    };
  },
  computed: {
    ...mapGetters("emulation", ["availableSegments"]),
    ...mapGetters("account/donor", ["availableStages"]),
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.settings.segment, function (value, key) {
        if (value !== null) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
    enabledModules: function () {
      let modules = [];
      _.forIn(this.settings.settings, function (value, key) {
        if (_.get(value, "value") === true) {
          modules.push(key);
        }
      });
      return modules;
    },
  },
  methods: {
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    prepareAttributeValue: function (attribute, value) {
      let result = value;
      const component = this;
      if (_.isArray(value)) {
        return value
          .map(function (itemValue) {
            return component.prepareAttributeValue(attribute, itemValue);
          })
          .join(", ");
      }
      return result;
    },
  },
};
</script>
