<template>
  <div>
    <emulation-settings-form-item-switcher
      v-if="isSwitcher"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
      :paramsMapping="paramsMapping"
    ></emulation-settings-form-item-switcher>
    <emulation-settings-form-item-range
      v-else-if="isRange"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-range>
    <emulation-settings-form-item-number
      v-else-if="isNumber"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-number>
    <emulation-settings-form-item-string
      v-else-if="isString"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-string>
    <emulation-settings-form-item-select
      v-else-if="isSelect"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-select>
    <emulation-settings-form-item-scroll-down-limit
      v-else-if="isScrollDownLimit"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-scroll-down-limit>
    <emulation-settings-form-item-friending-limit
      v-else-if="isFriendingLimit"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-friending-limit>
    <emulation-settings-form-item-posting-day-limit
      v-else-if="isPostingDayLimit"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-posting-day-limit>
    <emulation-settings-form-item-posting-options
      v-else-if="isPostingOptions"
      :paramsMapping="paramsMapping"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-posting-options>
    <emulation-settings-form-item-multiselect
      v-else-if="isMultiselect"
      :paramsMapping="paramsMapping"
      :label="label"
      v-model="itemValue"
      :readonly="readonly"
    ></emulation-settings-form-item-multiselect>
    <div v-else>
      {{ label }}
      <div v-for="(item, label) in value.children" :key="label">
        <emulation-settings-form-item
          v-model="value.children[label]"
          :label="label"
          :readonly="readonly"
          :paramsMapping="paramsMapping.children[label]"
        ></emulation-settings-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TYPE_NUMBER,
  TYPE_SWITCHER,
  TYPE_RANGE,
  TYPE_SELECT,
  TYPE_SCROLL_DOWN_LIMIT,
  TYPE_FRIENDING_LIMIT,
  TYPE_POSTING_DAY_LIMIT,
  TYPE_POSTING_OPTIONS,
  TYPE_STRING,
  TYPE_MULTISELECT,
} from "@/store/modules/emulation/getters";

import EmulationSettingsFormItemSwitcher from "./EmulationSettingsFormItemSwitcher";
import EmulationSettingsFormItemRange from "./EmulationSettingsFormItemRange";
import EmulationSettingsFormItemNumber from "./EmulationSettingsFormItemNumber";
import EmulationSettingsFormItemString from "./EmulationSettingsFormItemString";
import EmulationSettingsFormItemSelect from "./EmulationSettingsFormItemSelect";
import EmulationSettingsFormItemScrollDownLimit from "./EmulationSettingsFormItemScrollDownLimit";
import EmulationSettingsFormItemFriendingLimit from "./EmulationSettingsFormItemFriendingLimit";
import EmulationSettingsFormItemPostingDayLimit from "./EmulationSettingsFormItemPostingDayLimit";
import EmulationSettingsFormItemPostingOptions from "./EmulationSettingsFormItemPostingOptions";
import EmulationSettingsFormItemMultiselect from "./EmulationSettingsFormItemMultiselect.vue";

export default {
  name: "EmulationSettingsFormItem",
  components: {
    EmulationSettingsFormItemSwitcher,
    EmulationSettingsFormItemRange,
    EmulationSettingsFormItemNumber,
    EmulationSettingsFormItemString,
    EmulationSettingsFormItemSelect,
    EmulationSettingsFormItemScrollDownLimit,
    EmulationSettingsFormItemFriendingLimit,
    EmulationSettingsFormItemPostingDayLimit,
    EmulationSettingsFormItemPostingOptions,
    EmulationSettingsFormItemMultiselect,
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
    paramsMapping: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemValue: this.value,
    };
  },
  computed: {
    isSwitcher: function () {
      return this.paramsMapping.type === TYPE_SWITCHER;
    },
    isRange: function () {
      return this.paramsMapping.type === TYPE_RANGE;
    },
    isNumber: function () {
      return this.paramsMapping.type === TYPE_NUMBER;
    },
    isString: function () {
      return this.paramsMapping.type === TYPE_STRING;
    },
    isSelect: function () {
      return this.paramsMapping.type === TYPE_SELECT;
    },
    isScrollDownLimit: function () {
      return this.paramsMapping.type === TYPE_SCROLL_DOWN_LIMIT;
    },
    isFriendingLimit: function () {
      return this.paramsMapping.type === TYPE_FRIENDING_LIMIT;
    },
    isPostingDayLimit: function () {
      return this.paramsMapping.type === TYPE_POSTING_DAY_LIMIT;
    },
    isPostingOptions: function () {
      return this.paramsMapping.type === TYPE_POSTING_OPTIONS;
    },
    isMultiselect: function () {
      return this.paramsMapping.type === TYPE_MULTISELECT;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
