<template>
  <div>
    <v-row v-if="!isEmpty(itemValue.children)" justify="center">
      <v-expansion-panels v-model="expandedItem" flat>
        <v-expansion-panel readonly>
          <v-expansion-panel-header @click.prevent="">
            <span class="text-subtitle-1 font-weight-medium">{{
              $t(`emulation.params.${label}`)
            }}</span>

            <template v-slot:actions>
              <v-switch v-model="itemValue.value" @click.prevent=""></v-switch>
              <v-icon @click="togglePanel"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="(item, label) in itemValue.children" :key="label">
              <emulation-settings-form-item
                v-model="value.children[label]"
                :label="label"
                :readonly="!itemValue.value"
                :paramsMapping="paramsMapping.children[label]"
              ></emulation-settings-form-item>
            </div>
            <v-divider class="mb-0 mt-3"></v-divider>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <div v-else class="px-3 d-flex justify-space-between">
      <span class="text-subtitle-1" style="line-height: 60px">{{
        $t(`emulation.params.${label}`)
      }}</span>
      <v-switch
        :readonly="readonly"
        v-model="itemValue.value"
        class="mr-6"
      ></v-switch>
    </div>
    <v-alert
      v-if="value.appliedValue !== null && value.appliedValue !== value.value"
      dense
      outlined
      type="info"
      class="mt-4"
    >
      {{ $t("emulation.settingsDiff") }}<br />
      {{ $t("emulation.appliedValue", { appliedValue: value.appliedValue }) }}
    </v-alert>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "EmulationSettingsFormItemSwitcher",
  components: {
    EmulationSettingsFormItem: () => import("./EmulationSettingsFormItem"),
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
    paramsMapping: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemValue: this.value,
      expandedItem: null,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
    itemValue: {
      handler: function () {
        this.itemValue = this.value;
        this.expandedItem = this.itemValue.value === true ? 0 : null;
        if (!this.itemValue.value) {
          this.resetChildren(this.itemValue.children);
          this.handleInput();
        }
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    togglePanel: function () {
      this.expandedItem = this.expandedItem === 0 ? null : 0;
    },
    resetChildren: function (children) {
      const vm = this;
      _.forIn(children, function (item) {
        item.value = item.appliedValue;
        if (item.children) {
          vm.resetChildren(item.children);
        }
      });
    },
  },
  mounted() {
    this.expandedItem = this.itemValue.value === true ? 0 : null;
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
