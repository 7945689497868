<template>
  <v-card>
    <v-form @submit.prevent="updateItem" ref="form">
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        <span v-if="item">
          {{
            $t("recommender.editSettingsFor", {
              scope: getServiceName({ scope }),
            })
          }}
        </span>
        <span v-else>
          {{
            $t("recommender.addSettingsFor", {
              scope: getServiceName({ scope }),
            })
          }}
        </span>
        <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="px-3" style="position: relative">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card-text style="height: calc(100vh - 150px); overflow-y: auto">
          <v-layout align-center justify-center>
            <v-flex xs12 sm12 md10 lg8>
              <div class="pl-3">
                <div class="subtitle-1 pb-3 font-weight-bold">
                  {{ $t("recommender.segmentSettings") }}
                </div>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :readonly="!isEmpty(item)"
                      :clearable="isEmpty(item)"
                      :items="availableAccountTypeLabels"
                      item-text="title"
                      item-value="id"
                      menu-props="closeOnContentClick"
                      deletable-chips
                      small-chips
                      hide-no-data
                      hide-selected
                      name="type"
                      :label="attributeLabels.type"
                      v-model="settings.segment.type"
                      :rules="[(v) => !!v || $t('defaultErrors.required')]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      :readonly="!isEmpty(item)"
                      :clearable="isEmpty(item)"
                      :items="availableStages"
                      :label="attributeLabels.stage"
                      v-model="settings.segment.stage"
                      menu-props="closeOnContentClick"
                      deletable-chips
                      small-chips
                      hide-no-data
                      hide-selected
                      name="language"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <div class="subtitle-1 pb-3 font-weight-bold">
                  {{ $t("emulation.settings") }}
                </div>
              </div>
              <div v-for="(item, label) in settings.settings" :key="label">
                <emulation-settings-form-item
                  v-model="settings.settings[label]"
                  :label="label"
                  :paramsMapping="paramsMapping[label]"
                ></emulation-settings-form-item>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-alert v-if="isSegmentExist" dense outlined type="error">
            {{ $t("recommender.segmentExist") }}
          </v-alert>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="$emit('close')">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text type="submit">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import { PARAMS_MAPPING } from "@/store/modules/emulation/getters";

import EmulationSettingsFormItem from "./parts/EmulationSettingsFormItem";

import { PATCH_EMULATION_SETTINGS } from "@/store/actions/emulation-settings";
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "EmulationSettingsForm",
  components: { EmulationSettingsFormItem },
  props: {
    segment: Object,
    item: {
      required: true,
      type: Object,
    },
    scope: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      settings: {
        segment: this.item.segment,
        settings: _.merge({}, this.settingsStructure, this.item.settings),
      },
      attributeLabels: {
        type: this.$t("account.attribute.type"),
        stage: this.$t("account.attribute.stage"),
      },
      availableAccountTypeLabels: [],
    };
  },
  computed: {
    ...mapGetters("account/donor", ["availableStages"]),
    ...mapGetters("service", ["getServiceName"]),
    availableSegmentLabels: function () {
      let component = this;
      let result = [];
      this.availableSegments.forEach(function (segment) {
        result.push({
          key: segment,
          label: _.get(component.attributeLabels, segment),
        });
      });
      return result;
    },
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.settings.segment, function (value, key) {
        if (value.length) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
    isSegmentExist: function () {
      return (
        this.formErrors &&
        this.formErrors.segment &&
        this.formErrors.segment.indexOf("duplicate_segment") > -1
      );
    },
    settingsStructure: function () {
      let structure = {};

      let prepareStructureRecursively = function (module) {
        let moduleStructure = {
          value: null,
          appliedValue: null,
          children: {},
        };
        _.forIn(module, function (value, key) {
          moduleStructure.children[key] = prepareStructureRecursively(
            value.children
          );
        });
        return moduleStructure;
      };

      _.forIn(_.get(PARAMS_MAPPING, this.scope, []), function (value, key) {
        structure[key] = prepareStructureRecursively(value);
      });
      return structure;
    },
    paramsMapping: function () {
      return PARAMS_MAPPING[this.scope];
    },
  },
  methods: {
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        let { settings, scope } = this;
        this.$store
          .dispatch("emulation/" + PATCH_EMULATION_SETTINGS, {
            settings,
            type: scope,
            hash: this.item.hash,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    fetchAccountTypes: function () {
      this.isLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
        })
        .then(
          (payload) => {
            this.isLoading = false;
            this.availableAccountTypeLabels = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchAccountTypes();
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
