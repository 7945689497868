<template>
  <div>
    <div class="d-flex justify-space-between mb-2 px-3">
      <span class="mb-3 text-subtitle-1" style="line-height: 65px">{{
        $t(`emulation.params.${label}`)
      }}</span>
      <div>
        <div v-for="(item, index) in this.itemValue" :key="index">
          <v-row class="pt-3">
            <v-col>
              <v-autocomplete
                :items="paramsMapping.options"
                v-model="itemValue[index].type"
                :label="$t('emulation.type')"
                class="mt-0 pt-0"
                outlined
                dense
                style="width: 180px"
                @input="handleInput"
                :readonly="true"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                :readonly="readonly"
                v-model="itemValue[index].value"
                :rules="[
                  isNumeric,
                  (v) =>
                    totalSum === 100 ||
                    $t('defaultErrors.sumEqual', { sum: 100 }),
                ]"
                :label="$t('emulation.percent')"
                class="mt-0 pt-0"
                outlined
                dense
                style="width: 100px"
                @change="handleInput()"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <v-alert
      v-if="value.appliedValue && value.appliedValue !== value.value"
      dense
      outlined
      type="info"
    >
      {{ $t("emulation.settingsDiff") }}<br />
      {{ $t("emulation.appliedValue", { appliedValue: value.appliedValue }) }}
    </v-alert>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "EmulationSettingsFormItemPostingOptions",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      defaultValue: false,
    },
    paramsMapping: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemValue: this.preparedValue(),
    };
  },
  computed: {
    totalSum: function () {
      const total = _.map(this.itemValue, "value").reduce(
        (a, b) => parseInt(a) + parseInt(b),
        0
      );
      return total;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.preparedValue();
      },
      deep: true,
    },
  },
  methods: {
    isNumeric: function (v) {
      return (
        (Number(v) !== false &&
          Number.isInteger(Number(v)) &&
          Number(v) >= 0) ||
        this.$t("defaultErrors.integer")
      );
    },
    handleInput: function () {
      let value = [];

      this.itemValue.forEach(function (item) {
        value.push(`${item.type}:${item.value}`);
      });

      this.$emit(
        "input",
        _.merge(this.value, {
          value: value.join(","),
        })
      );
    },
    removeValue: function (index) {
      this.itemValue.splice(index, 1);
      this.handleInput();
    },
    addValue: function () {
      this.itemValue.push({
        type: "",
        value: 0,
      });
    },
    preparedValue: function () {
      let items = [];

      if (this.value.value) {
        this.value.value.split(",").forEach(function (item) {
          let parsedItem = item.split(":");
          if (parsedItem.length === 2) {
            items.push({
              type: parsedItem[0],
              value: Number(parsedItem[1]),
            });
          }
        });
      }

      const newOptions = _.difference(
        this.paramsMapping.options,
        _.map(items, "type")
      );

      newOptions.forEach(function (type) {
        items.push({
          type: type,
          value: 0,
        });
      });

      if (items.length === 0) {
        items.push({
          type: "",
          value: 0,
        });
      }
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
